<template>
    <div>
        <page-title :heading="$t('generic.lang_AdditionalStatistics')" :icon="icon"
                    :subheading="$t('generic.lang_AdditionalStatistics')"></page-title>
        <div class="app-main__inner">
            <AdditionalStatisticsComponent/>
        </div>
    </div>
</template>

<script>
    import PageTitle from "../../../../Layout/Components/PageTitle";
    import AdditionalStatisticsComponent
      from "@/components/analytics/warewanlytics/additionalstatistics/AdditionalStatisticsComponent";
    export default {
        name: "index",
        components: {AdditionalStatisticsComponent, PageTitle},
        data: () => ({
            icon: 'pe-7s-graph1 icon-gradient bg-tempting-azure',
        })
    }
</script>

<style scoped>

</style>